define('mhth/components/consultation-page', ['exports', 'mhth/config/environment', 'ember-state-services/state-for'], function (exports, _environment, _stateFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['consultation-page', 'layout-row', 'layout-align-space-around-start'],
    session: Ember.inject.service(),
    socketService: Ember.inject.service(),
    consultationsService: Ember.inject.service(),
    consultationState: (0, _stateFor.default)('consultation-state', 'consultation'),
    joined: Ember.computed('consultationsService.joinedConsultations.[]', function () {
      return this.get('consultationsService.joinedConsultations').includes(this.get('consultation.id'));
    }),
    callIsActive: Ember.computed('consultationState.status', function () {
      return this.get('consultationState.status') == 'video' || this.get('consultationState.status') == 'audio';
    }),
    showDoesNotAnsweredDialog: false,

    init: function init() {
      this._super.apply(this, arguments);
      var id = this.get('consultation.id');
      if (!this.get('consultationsService.consultations').includes(id)) {
        this.get('consultationsService.consultations').addObject(id);
      }
      this.get('socketService').authenticate();
      this.get('consultationsService').loadMessages(id);
    },


    actions: {
      rejectConsultation: function rejectConsultation() {
        var _this = this;

        this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.serverURL + '/' + _environment.default.apiNamespace + '/consultations/' + _this.get('consultation.id') + '/reject',
            data: { reason: _this.get('doesNotAnsweredReason') },
            headers: headers
          }).then(function (response) {
            if (response.success) {
              _this.toggleProperty('showDoesNotAnsweredDialog');
              _this.set('consultation.status', response.data.consultation.status);
              _this.get('socketService').stopConsultation(_this.get('consultation.id'));
            }
          });
        });
      },
      getPhone: function getPhone() {
        var _this2 = this;

        this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          Ember.$.ajax(_environment.default.serverURL + '/' + _environment.default.apiNamespace + '/consultations/' + _this2.get('consultation.id') + '/phone', { headers: headers }).then(function (response) {
            if (response.success) {
              _this2.set('consultation.phone', response.data.consultation.phone);
            }
          });
        });
      },
      startConsultation: function startConsultation() {
        var _this3 = this;

        this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          Ember.$.ajax(_environment.default.serverURL + '/' + _environment.default.apiNamespace + '/consultations/' + _this3.get('consultation.id') + '/call', { headers: headers });
          _this3.get('consultation').reload();
        });
      },
      sendTyping: function sendTyping(isTyping) {
        this.get('socketService').messageTypingConsultation(this.get('consultation.id'), isTyping);
      },
      sendMessage: function sendMessage(message) {
        this.get('socketService').messageConsultation(this.get('consultation.id'), message);
      },
      stopConsultation: function stopConsultation() {
        this.get('socketService').stopConsultation(this.get('consultation.id'));
      },
      acceptCallConsultation: function acceptCallConsultation(type) {
        this.get('socketService').acceptCallConsultation(this.get('consultation.id'), type);
      },
      requestCallConsultation: function requestCallConsultation(type) {
        this.get('socketService').requestCallConsultation(this.get('consultation.id'), type);
      },
      stopCallConsultation: function stopCallConsultation() {
        this.get('socketService').stopCallConsultation(this.get('consultation.id'));
      }
    }
  });
});
