define('mhth/components/consultation-opentok', ['exports', 'ember-state-services/state-for'], function (exports, _stateFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ConsultationOpenTokComponent = Ember.Component.extend({
    socketService: Ember.inject.service(),
    opentokService: Ember.inject.service(),
    consultationState: (0, _stateFor.default)('consultation-state', 'consultation'),
    localVideoClass: Ember.computed('consultationState.localVideo', function () {
      return this.get('consultationState.localVideo') ? 'videocam' : 'videocam-off';
    }),
    localAudioClass: Ember.computed('consultationState.localAudio', function () {
      return this.get('consultationState.localAudio') ? 'mic' : 'mic-off';
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('consultationState.localAudio', true);
      this.set('consultationState.localVideo', this.get('consultationState.status') == 'video');
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.get('opentokService').createSession(this.get('consultation'));
    },


    actions: {
      toggleLocalVideo: function toggleLocalVideo() {
        this.toggleProperty('consultationState.localVideo');
        this.get('opentokService').toggleLocalVideo(this.get('consultationState.localVideo'));
      },
      toggleLocalAudio: function toggleLocalAudio() {
        this.toggleProperty('consultationState.localAudio');
        this.get('opentokService').toggleLocalAudio(this.get('consultationState.localAudio'));
      },
      endCall: function endCall() {
        this.get('socketService').stopCallConsultation(this.get('consultation.id'));
      }
    }
  });

  ConsultationOpenTokComponent.reopenClass({
    positionalParams: ['consultation']
  });

  exports.default = ConsultationOpenTokComponent;
});
