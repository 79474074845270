define('mhth/models/consultation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    startedAt: _emberData.default.attr('date'),
    endedAt: _emberData.default.attr('date'),
    sessionId: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    patient: _emberData.default.belongsTo(),
    messages: _emberData.default.hasMany('message', { async: true }),

    totalTime: Ember.computed('startedAt', 'endedAt', function () {
      if (!(this.get('endedAt') && this.get('startedAt'))) {
        return 0;
      }
      return Math.round((this.get('endedAt') - this.get('startedAt')) / 60000);
    }),

    isStarted: Ember.computed('status', function () {
      return this.get('status') == 'started';
    }),

    isEnded: Ember.computed('status', function () {
      return this.get('status') == 'ended' || this.get('status') == 'rejected';
    }),

    isPaid: Ember.computed('status', function () {
      return this.get('status') == 'paid';
    }),

    isActive: Ember.computed('status', function () {
      return this.get('status') == 'paid' || this.get('status') == 'started';
    })
  });
});
