define('mhth/adapters/application', ['exports', 'mhth/config/environment', 'active-model-adapter', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _environment, _activeModelAdapter, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:oauth2',
    host: _environment.default.serverURL,
    corsWithCredentials: true,
    namespace: _environment.default.apiNamespace,
    pathForType: function pathForType(type) {
      var underscored = Ember.String.underscore(type);
      return Ember.String.pluralize(underscored);
    }
  });
});
