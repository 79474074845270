define('mhth/routes/dashboard/consultations/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('consultation');
    },
    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});
