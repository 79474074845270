define('mhth/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'mhth/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP,
      isEmpty = Ember.isEmpty,
      run = Ember.run,
      makeArray = Ember.makeArray,
      emberAssign = Ember.assign,
      merge = Ember.merge;

  var assign = emberAssign || merge;

  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.serverURL + '/oauth/token',
    serverTokenRevocationEndpoint: _environment.default.serverURL + '/oauth/revoke',

    // just add 'role': 'doctor', nothing else
    authenticate: function authenticate(identification, password) {
      var _this = this;

      var scope = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      return new RSVP.Promise(function (resolve, reject) {
        var data = {
          grant_type: 'password',
          role: 'doctor',
          username: identification,
          password: password
        };
        var serverTokenEndpoint = _this.get('serverTokenEndpoint');
        var useResponse = _this.get('rejectWithResponse');
        var scopesString = makeArray(scope).join(' ');
        if (!isEmpty(scopesString)) {
          data.scope = scopesString;
        }
        _this.makeRequest(serverTokenEndpoint, data, headers).then(function (response) {
          run(function () {
            if (!_this._validate(response)) {
              reject('access_token is missing in server response');
            }

            var expiresAt = _this._absolutizeExpirationTime(response['expires_in']);
            _this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);
            if (!isEmpty(expiresAt)) {
              response = assign(response, { expires_at: expiresAt });
            }

            resolve(response);
          });
        }, function (response) {
          run(null, reject, useResponse ? response : response.responseJSON);
        });
      });
    }
  });
});
