define('mhth/controllers/dashboard/schedule/index', ['exports', 'mhth/utils/calendar-factory'], function (exports, _calendarFactory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    months: [{ id: 0, title: 'Январь' }, { id: 1, title: 'Февраль' }, { id: 2, title: 'Март' }, { id: 3, title: 'Апрель' }, { id: 4, title: 'Май' }, { id: 5, title: 'Июнь' }, { id: 6, title: 'Июль' }, { id: 7, title: 'Август' }, { id: 8, title: 'Сентябрь' }, { id: 9, title: 'Октябрь' }, { id: 10, title: 'Ноябрь' }, { id: 11, title: 'Декабрь' }],
    today: new Date(),
    day: new Date(),
    month: Ember.computed('day', function () {
      return this.get('day').getMonth() + 1;
    }),
    calendar: Ember.computed('day', function () {
      return (0, _calendarFactory.default)(this.get('day').getFullYear(), this.get('day').getMonth());
    })
  });
});
