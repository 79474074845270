define('mhth/services/opentok-service', ['exports', 'npm:opentok-accelerator-core', 'ember-state-services/state-for'], function (exports, _npmOpentokAcceleratorCore, _stateFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: null,
    connected: false,
    active: false,
    stream: null,

    createSession: function createSession(consultation) {
      var _this = this;

      if (!this.get('connected')) {
        var state = (0, _stateFor.default)('consultation-state').get(consultation);
        this.set('session', new _npmOpentokAcceleratorCore.default(this.__otCoreOptions(state.get('openTokSession'))));

        this.get('session').connect().then(function () {
          _this.set('connected', true);
          _this.startCall({ publishVideo: state.get('localVideo') });
        }).catch(function (error) {
          return Ember.Logger.log(error);
        });
      }
    },
    startCall: function startCall(params) {
      var _this2 = this;

      if (!this.get('active')) {
        this.get('session').startCall(params).then(function () {
          _this2.set('active', true);
        }).catch(function (error) {
          return Ember.Logger.log(error);
        });
      }
    },
    endCall: function endCall() {
      if (this.get('connected')) {
        this.get('session').endCall();
        this.get('session').disconnect();
        this.set('connected', false);
        this.set('active', false);
        this.set('stream', null);
      }
    },
    toggleLocalVideo: function toggleLocalVideo(enable) {
      this.get('session').toggleLocalVideo(enable);
    },
    toggleLocalAudio: function toggleLocalAudio(enable) {
      this.get('session').toggleLocalAudio(enable);
    },
    __otCoreOptions: function __otCoreOptions(session) {
      return {
        credentials: {
          apiKey: session.api_key,
          sessionId: session.id,
          token: session.token
        },
        streamContainers: function streamContainers(pubSub /*, type, data, streamId*/) {
          return {
            publisher: '#publisher',
            subscriber: '#subscriber'
          }[pubSub];
        }
      };
    }
  });
});
