define("mhth/utils/calendar-factory", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = calendarFactory;
  function calendarFactory(year, month) {
    var results = [];
    var firstDate = new Date(year, month, 1);
    var lastDate = new Date(year, month + 1, 0);
    var firstMonday = getFirstMonday(firstDate);
    var lastSunday = getLastSunday(lastDate);

    var iterator = new Date(firstMonday);
    var i = 0;
    var week = [];

    while (iterator <= lastSunday) {
      if (i++ % 7 === 0) {
        week = [];
        results.push(week);
      }

      week.push({
        date: new Date(iterator),
        before: iterator < firstDate,
        after: iterator > lastDate
      });

      iterator.setDate(iterator.getDate() + 1);
    }

    return results;
  }

  function getFirstMonday(firstDate) {
    var offset = firstDate.getDay() - 1;

    var result = new Date(firstDate);
    result.setDate(firstDate.getDate() - offset);

    return result;
  }

  function getLastSunday(lastDate) {
    var offset = 7 - lastDate.getDay();

    var result = new Date(lastDate);
    result.setDate(lastDate.getDate() + offset);

    return result;
  }
});
