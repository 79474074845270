define('mhth/controllers/dashboard/consultations/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    onlyActive: true,

    filteredConsultations: Ember.computed('session.data.filterOnlyActive', 'model.@each.status', function () {
      var consultations = this.get('model');
      var isActive = this.get('session.data.filterOnlyActive');

      if (isActive) {
        return consultations.filterBy('isActive', isActive);
      } else {
        return consultations;
      }
    }),

    actions: {
      filterOnlyActive: function filterOnlyActive() {
        if (this.get('session.data.filterOnlyActive')) {
          this.get('session').set('data.filterOnlyActive', false);
        } else {
          this.get('session').set('data.filterOnlyActive', true);
        }
      }
    }
  });
});
