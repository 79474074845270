define('mhth/models/patient', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    patronymic: _emberData.default.attr('string'),
    age: _emberData.default.attr('number'),

    fullName: Ember.computed('firstName', 'patronymic', function () {
      return [this.get('firstName'), this.get('patronymic')].join(' ');
    })
  });
});
