define('mhth/models/message', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    consultation: _emberData.default.belongsTo('consultation'),
    text: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    sentOn: _emberData.default.attr('number'),
    url: _emberData.default.attr('string'),
    thumbnail: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    user: _emberData.default.attr(),
    sentOnMs: Ember.computed('sentOn', function () {
      return this.get('sentOn') * 1000;
    })
  });
});
