define('mhth/components/consultation-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['consultation-page__info', 'layout-row', 'layout-align-space-around-center', 'flex']
  });
});
