define('mhth/routes/dashboard/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('dashboard.index').set('currentUser', this.get('currentUser.user'));
    }
  });
});
