define('mhth/components/file-upload', ['exports', 'ember-uploader', 'mhth/config/environment', 'npm:uuid'], function (exports, _emberUploader, _environment, _npmUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),

    didRender: function didRender() {
      $('input').click();
    },

    actions: {
      filesDidChange: function filesDidChange(files, resetInput) {
        var uploader = _emberUploader.default.Uploader.create({
          url: _environment.default.serverURL + '/' + _environment.default.apiNamespace + '/' + this.get('url')
        });

        if (!Ember.isEmpty(files)) {
          this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
            var headers = {};
            headers[headerName] = headerValue;
            uploader.set('ajaxSettings.headers', headers);
            uploader.upload(files[0], {
              message_id: (0, _npmUuid.default)(),
              sent_on: Math.floor(Date.now() / 1000)
            });
          });

          resetInput();
          this.sendAction('onUpload');
        }
      }
    }
  });
});
