define('mhth/components/consultation-chat-item', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    viewportOptionsOverride: Ember.on('didInsertElement', function () {
      Ember.setProperties(this, {
        viewportSpy: true,
        viewportRefreshRate: 300
      });
    }),
    currentUser: Ember.inject.service(),
    socketService: Ember.inject.service(),
    defaultAvatar: 'https://amploprod.s3.amazonaws.com/assets/no-user-image-square-9f6a473a32ad639f619216331d10d61ce1b35c9271d5683920960e1a5ee45bb8.jpg',
    byCurrentUser: Ember.computed(function () {
      return this.get('message.user.id') == this.get('currentUser.user.id');
    }),

    chatClass: Ember.computed('message.status', function () {
      if (!this.get('byCurrentUser') && this.get('message.status') != 'read') {
        return "consultation-page__chat-item consultation-page__chat-item--not-read";
      } else {
        return "consultation-page__chat-item";
      }
    }),

    showAvatar: Ember.computed(function () {
      if (this.get('previousMessage.id') == this.get('message.id')) {
        return true; // ugly fix for first message
      }

      return this.get('message.user.id') !== this.get('previousMessage.user.id');
    }),

    avatarUrl: Ember.computed(function () {
      if (this.get('byCurrentUser')) {
        return this.get('currentUser.user.thumbnailUrl');
      }
      return this.get('defaultAvatar');
    }),

    username: Ember.computed(function () {
      if (this.get('byCurrentUser')) {
        return 'Вы';
      }
      return this.get('message.user.username');
    }),

    didEnterViewport: function didEnterViewport() {
      if (!this.get('byCurrentUser') && this.get('message.status') != 'read') {
        this.get('socketService').messageStatusConsultation(this.get('message.consultation.id'), this.get('message.id'), 'read');
      }
    }
  });
});
