define('mhth/adapters/message', ['exports', 'mhth/adapters/application', 'npm:uuid'], function (exports, _application, _npmUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query /*, modelName*/) {
      if (query.consultationId) {
        return this._buildURL('consultation', query.consultationId) + '/messages';
      }
      return this.__super.apply(this, arguments);
    },

    generateIdForRecord: function generateIdForRecord() /*store, inputProperties*/{
      return (0, _npmUuid.v4)();
    }
  });
});
