define('mhth/services/socket-service', ['exports', 'npm:uuid'], function (exports, _npmUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    socketIOService: Ember.inject.service('socket-io'),
    session: Ember.inject.service(),
    authenticated: false,
    socket: null,
    listenConsultation: false,

    consultationsService: Ember.inject.service(),
    consultations: Ember.computed.alias('consultationsService.consultations'),
    joinedConsultations: Ember.computed.alias('consultationsService.joinedConsultations'),

    authenticate: function authenticate() {
      var _this = this;

      if (!this.get('socket')) {
        this.set('socket', this.get('socketIOService').socketFor('https://myhealthapp.mhth.ru/'));
      }
      if (!this.get('authenticated')) {
        this.get('socket').on('connect', this.onConnect, this);
        this.get('socket').on('disconnect', this.onDisconnect, this);
        this.get('socket').on('authenticated', this.onAuthenticated, this);
      } else {
        this.get('consultations').forEach(function (consultationId) {
          _this.joinConsultation(consultationId);
        });
      }
    },
    onConnect: function onConnect(data) {
      var _this2 = this;

      Ember.Logger.log('onConnect', data);
      this.get('session').authorize('authorizer:oauth2', function (_, headerValue) {
        _this2.get('socket').emit('authentication', {
          token: headerValue.replace('Bearer ', '')
        });
      });
    },
    onDisconnect: function onDisconnect(data) {
      this.set('joinedConsultations', []);
      Ember.Logger.log('disconnected', data);
    },
    onAuthenticated: function onAuthenticated() {
      var _this3 = this;

      Ember.Logger.log('authenticated');
      Ember.Logger.log(this.get('consultations'));
      Ember.Logger.log(this.get('joinedConsultations'));

      this.set('authenticated', true);
      this.get('consultations').forEach(function (consultationId) {
        _this3.joinConsultation(consultationId);
      });
    },
    joinConsultation: function joinConsultation(id) {
      if (!this.get('listenConsultation')) {
        var consultationsService = this.get('consultationsService');
        this.get('socket').on('accept_call_consultation', consultationsService.onAcceptCallConsultation, consultationsService);
        this.get('socket').on('stop_consultation', consultationsService.onStopConsultation, consultationsService);
        this.get('socket').on('reject_consultation', consultationsService.onStopConsultation, consultationsService);
        this.get('socket').on('stop_call_consultation', consultationsService.onStopCallConsultation, consultationsService);
        this.get('socket').on('start_consultation', consultationsService.onStartConsultation, consultationsService);
        this.get('socket').on('join_consultation', consultationsService.onJoinConsultation, consultationsService);
        this.get('socket').on('request_call_consultation', consultationsService.onRequestCallConsultation, consultationsService);
        this.get('socket').on('message_consultation', consultationsService.onMessageConsultation, consultationsService);
        this.get('socket').on('message_typing_consultation', consultationsService.onMessageTypingConsultation, consultationsService);
        this.get('socket').on('message_status_consultation', consultationsService.onMessageStatusConsultation, consultationsService);
        this.set('listenConsultation', true);
      }

      if (!this.get('joinedConsultations').includes(id)) {
        this.get('joinedConsultations').addObject(id);
        this.get('socket').emit('join_consultation', {
          consultation_id: id,
          sent_on: Math.floor(Date.now() / 1000)
        });
      }
    },
    stopConsultation: function stopConsultation(id) {
      this.get('socket').emit('stop_consultation', {
        consultation_id: id,
        sent_on: Math.floor(Date.now() / 1000)
      });
    },
    stopCallConsultation: function stopCallConsultation(id) {
      this.get('socket').emit('stop_call_consultation', {
        consultation_id: id,
        sent_on: Math.floor(Date.now() / 1000)
      });
    },
    acceptCallConsultation: function acceptCallConsultation(id, type) {
      this.get('socket').emit('accept_call_consultation', {
        consultation_id: id,
        type: type,
        sent_on: Math.floor(Date.now() / 1000)
      });
    },
    requestCallConsultation: function requestCallConsultation(id, type) {
      this.get('socket').emit('request_call_consultation', {
        consultation_id: id,
        type: type,
        sent_on: Math.floor(Date.now() / 1000)
      });
    },
    messageConsultation: function messageConsultation(id, message) {
      this.get('socket').emit('status_consultation', {
        consultation_id: id
      });

      this.get('socket').emit('message_consultation', {
        consultation_id: id,
        id: (0, _npmUuid.default)(),
        type: 'text',
        text: message,
        sent_on: Math.floor(Date.now() / 1000)
      });
    },
    messageTypingConsultation: function messageTypingConsultation(id, typing) {
      this.get('socket').emit('message_typing_consultation', {
        consultation_id: id,
        typing: typing
      });
    },
    messageStatusConsultation: function messageStatusConsultation(consultation_id, message_id, status) {
      this.get('socket').emit('message_status_consultation', {
        consultation_id: consultation_id,
        message_id: message_id,
        status: status
      });
    }
  });
});
