define('mhth/services/consultations-service', ['exports', 'ember-state-services/state-for'], function (exports, _stateFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    opentokService: Ember.inject.service(),
    socketService: Ember.inject.service(),
    consultations: [],
    joinedConsultations: [],

    loadMessages: function loadMessages(id) {
      this.get('store').query('message', { consultationId: id });
    },
    onMessageConsultation: function onMessageConsultation(data) {
      Ember.Logger.log('onMessageConsultation', data);

      this.get('store').pushPayload({ message: data });

      if (data.user.id != this.get('currentUser.user.id')) {
        this.get('socketService').messageStatusConsultation(data.consultation_id, data.id, 'delivered');
      }
    },
    onMessageTypingConsultation: function onMessageTypingConsultation(data) {
      Ember.Logger.log('onMessageTypingConsultation', data);

      var consultation = this.get('store').peekRecord('consultation', data.consultation_id);
      var state = (0, _stateFor.default)('consultation-state').get(consultation);

      state.set('typing', data.typing);
    },
    onMessageStatusConsultation: function onMessageStatusConsultation(data) {
      Ember.Logger.log('onMessageStatusConsultation', data);

      var message = this.get('store').peekRecord('message', data.message_id);

      message.set('status', data.status);
    },
    onStartConsultation: function onStartConsultation(data) {
      Ember.Logger.log('onStartConsultation', data);

      var consultation = this.get('store').peekRecord('consultation', data.consultation_id);
      consultation.set('status', 'started');
      consultation.set('startedAt', new Date(data.started_at * 1000));
      var state = (0, _stateFor.default)('consultation-state').get(consultation);

      state.set('status', data.type);
      state.set('openTokSession', data.session);
    },
    onRequestCallConsultation: function onRequestCallConsultation(data) {
      Ember.Logger.log('onRequestCallConsultation', data);
      var consultation = this.get('store').peekRecord('consultation', data.consultation_id);
      var state = (0, _stateFor.default)('consultation-state').get(consultation);

      if (data.user.id != this.get('currentUser.user.id')) {
        state.set('call', 'incoming');
      } else {
        state.set('call', 'outcoming');
      }
    },
    onAcceptCallConsultation: function onAcceptCallConsultation(data) {
      Ember.Logger.log('onAcceptCallConsultation', data);

      var consultation = this.get('store').peekRecord('consultation', data.consultation_id);
      var state = (0, _stateFor.default)('consultation-state').get(consultation);

      state.set('status', data.type);
      state.set('openTokSession', data.session);
      state.set('call', null);
    },
    onStopCallConsultation: function onStopCallConsultation(data) {
      Ember.Logger.log('onStopCallConsultation', data);

      var consultation = this.get('store').peekRecord('consultation', data.consultation_id);
      var state = (0, _stateFor.default)('consultation-state').get(consultation);

      state.set('status', 'chat');
      state.set('openTokSession', null);
      state.set('call', null);
      this.get('opentokService').endCall();
    },
    onJoinConsultation: function onJoinConsultation(data) {
      Ember.Logger.log('onJoinConsultation', data);
      this.get('consultations').addObject(data.consultation_id);
    },
    onStopConsultation: function onStopConsultation(data) {
      Ember.Logger.log('onStopConsultation', data);

      //TODO: много чего, наверное

      this.get('consultations').removeObject(data.consultation_id);

      var consultation = this.get('store').peekRecord('consultation', data.consultation_id);
      consultation.set('status', 'ended');
      consultation.set('endedAt', new Date(data.ended_at * 1000));
      var state = (0, _stateFor.default)('consultation-state').get(consultation);

      state.set('status', null);
      state.set('openTokSession', null);
      state.set('call', null);
    }
  });
});
