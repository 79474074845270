define('mhth/serializers/application', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    /*
      @method _normalizeResponse
      @param {DS.Store} store
      @param {DS.Model} primaryModelClass
      @param {Object} payload
      @param {String|Number} id
      @param {String} requestType
      @param {Boolean} isSingle
      @return {Object} JSON-API Document
      @private
    */
    _normalizeResponse: function _normalizeResponse(store, primaryModelClass, payload) {
      if (payload.data) {
        payload = payload.data;
      }

      for (var _len = arguments.length, attrs = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        attrs[_key - 3] = arguments[_key];
      }

      return this._super.apply(this, [store, primaryModelClass, payload].concat(_toConsumableArray(attrs)));
    }
  });
});
